export const styles = {
  global: {
    body: {
      bg: 'brand.0',
      color: 'brand.950',
      fontSize: '14px',
      // Required to make the toast corners square
      '.chakra-alert': {
        borderRadius: 0,
      },
    },
  },
};
