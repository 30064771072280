export const Tooltip = {
  baseStyle: {
    borderRadius: '0',
    fontWeight: 'normal',
    background: 'brand.0',
    arrowShadowColor: 'brand.0',
    border: '0.5px solid',
    px: '5',
    py: '3',
  },
};
