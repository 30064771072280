import { createIcon } from '@chakra-ui/icons';

export const SuccessIcon = createIcon({
  displayName: 'SuccessIcon',
  viewBox: '0 0 150 150',
  path: (
    <path
      fill="currentColor"
      d="M75 0C60.1664 0 45.6659 4.39867 33.3323 12.6398C20.9986 20.8809 11.3856 32.5943 5.70907 46.2987C0.0324964 60.0032 -1.45275 75.0832 1.44114 89.6318C4.33503 104.18 11.4781 117.544 21.967 128.033C32.456 138.522 45.8197 145.665 60.3683 148.559C74.9168 151.453 89.9968 149.968 103.701 144.291C117.406 138.614 129.119 129.001 137.36 116.668C145.601 104.334 150 89.8336 150 75C149.977 55.1159 142.068 36.0528 128.007 21.9926C113.947 7.93236 94.8841 0.0231576 75 0V0ZM118.294 51.25L75.5125 109.306C75.0074 109.976 74.3739 110.539 73.649 110.962C72.924 111.384 72.1221 111.658 71.2902 111.768C70.4582 111.877 69.6127 111.82 68.8032 111.599C67.9936 111.378 67.2362 110.998 66.575 110.481L36.025 86.0562C35.384 85.5433 34.8503 84.909 34.4544 84.1898C34.0585 83.4706 33.8081 82.6804 33.7176 81.8644C33.5347 80.2164 34.014 78.5633 35.05 77.2687C36.086 75.9742 37.5939 75.1441 39.2418 74.9613C40.8898 74.7784 42.5429 75.2577 43.8375 76.2937L69.3125 96.675L108.231 43.8562C108.7 43.1532 109.306 42.5524 110.013 42.0902C110.721 41.628 111.514 41.314 112.346 41.1672C113.178 41.0204 114.031 41.0438 114.854 41.2361C115.677 41.4283 116.452 41.7853 117.133 42.2856C117.814 42.7859 118.386 43.419 118.815 44.1467C119.245 44.8745 119.522 45.6817 119.63 46.5196C119.739 47.3575 119.676 48.2087 119.447 49.0217C119.217 49.8348 118.825 50.5928 118.294 51.25Z"
    />
  ),
});
